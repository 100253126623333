<template>
  <div>
    <div class="bet-tool-menu-items">
      <div
        class="bet-tool-menu-items__item-menu-container"
        :class="{ 'bet-opt-disabled': active != 'none' && active != 'resultado' }"
      >
        <div
          class="bet-tool-menu-items__item-icon icon-resultado"
          :class="{ 'not-cursor': active != 'none' && active != 'resultado' }"
          @click="selectedOption('resultado')"
        >
          <img src="/images/icn-resultado@2x.png" />
        </div>
        <div class="bet-tool-menu-items__item-text">Apuesta por resultado</div>
        <div class="bet-tool-menu-items__item-text-movil">Por resultado</div>
        <!-- <ModalSubMenuBetToolMenu
          :sections="options.result.options"
          :show="active != 'none' && active == 'resultado' ? true : false"
          :grid="2"
          section="resultado"
          @close-modal="closeModalEvent"
        /> -->
      </div>
      <div
        class="bet-tool-menu-items__item-menu-container"
        @click="selectedOption('tiros')"
        :class="{ 'bet-opt-disabled': active != 'none' && active != 'tiros' }"
      >
        <div
          class="bet-tool-menu-items__item-icon icon-corner"
          :class="{ 'not-cursor': active != 'none' && active != 'tiros' }"
        >
          <img src="/images/icn-corner@2x.png" />
        </div>
        <div class="bet-tool-menu-items__item-text">Apuesta por tiros de esquina</div>
        <div class="bet-tool-menu-items__item-text-movil">Por tiros de esquina</div>
        <ModalSubMenuBetToolMenu
          :sections="options.corners.options"
          :grid="3"
          :show="active != 'none' && active == 'tiros' ? true : false"
          section="tiros"
          @close-modal="closeModalEvent"
        />
      </div>
      <div
        class="bet-tool-menu-items__item-menu-container"
        @click="selectedOption('goles')"
        :class="{ 'bet-opt-disabled': active != 'none' && active != 'goles' }"
      >
        <div
          class="bet-tool-menu-items__item-icon icon-goles"
          :class="{ 'not-cursor': active != 'none' && active != 'goles' }"
        >
          <img src="/images/icn-goles@2x.png" />
        </div>
        <div class="bet-tool-menu-items__item-text">Apuesta por goles</div>
        <div class="bet-tool-menu-items__item-text-movil">Por goles</div>
        <ModalSubMenuBetToolMenu
          :sections="options.goals.options"
          :grid="2"
          :show="active != 'none' && active == 'goles' ? true : false"
          section="goles"
          @close-modal="closeModalEvent"
        />
      </div>
      <div
        class="bet-tool-menu-items__item-menu-container"
        @click="selectedOption('mitad')"
        :class="{ 'bet-opt-disabled': active != 'none' && active != 'mitad' }"
      >
        <div
          class="bet-tool-menu-items__item-icon icon-mitad"
          :class="{ 'not-cursor': active != 'none' && active != 'mitad' }"
        >
          <img src="/images/icn-por-mitad@2x.png" />
        </div>
        <div class="bet-tool-menu-items__item-text">Apuesta por mitad</div>
        <div class="bet-tool-menu-items__item-text-movil">Por mitad</div>
        <ModalSubMenuBetToolMenu
          :sections="options.half.options"
          :grid="2"
          :show="active != 'none' && active == 'mitad' ? true : false"
          section="mitad"
          @close-modal="closeModalEvent"
        />
      </div>
      <div
        class="bet-tool-menu-items__item-menu-container"
        @click="selectedOption('parley')"
        :class="{ 'bet-opt-disabled': active != 'none' && active != 'parley' }"
      >
        <div
          class="bet-tool-menu-items__item-icon icon-parlay"
          :class="{ 'not-cursor': active != 'none' && active != 'parley' }"
        >
          <img src="/images/icn-parlay@2x.png" />
        </div>
        <div class="bet-tool-menu-items__item-text">Apuesta por parlay</div>
        <div class="bet-tool-menu-items__item-text-movil">Parlay</div>
      </div>
      <div
        class="bet-tool-menu-items__item-menu-container"
        @click="selectedOption('tips')"
        :class="{ 'bet-opt-disabled': active != 'none' && active != 'tips' }"
      >
        <div
          class="bet-tool-menu-items__item-icon icon-golstats"
          :class="{ 'not-cursor': active != 'none' && active != 'tips' }"
        >
          <img src="/images/icn-tip-golstats@3x.png" />
        </div>
        <div class="bet-tool-menu-items__item-text">Tip Científico</div>
        <div class="bet-tool-menu-items__item-text-movil">Tip Científico</div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalSubMenuBetToolMenu from '@/components/Sections/BetTool/ModalSubMenuBetToolMenu';
import axios from 'axios';
export default {
  name: 'Menu',
  components: {
    ModalSubMenuBetToolMenu,
  },
  data() {
    return {
      numClicks: 0,
      active: 'none',
      options: {
        result: {
          id: 1,
          options: [],
        },
        corners: {
          id: 4,
          options: [],
        },
        goals: {
          id: 3,
          options: [],
        },
        half: {
          id: 2,
          options: [],
        },
      },
      menuResultado: [
        { name: 'Doble oportunidad/Money line', url: '/' },
        { name: 'Marcador correcto', url: '/' },
        { name: 'Marcador correcto descanso', url: '/' },
        { name: 'Marcador correcto 2da mitad', url: '/' },
      ],
      menuCorner: [
        { name: 'Corners total', url: '/' },
        { name: '1era. Mitad – corners', url: '/' },
        { name: 'Equipo con más tiros de esquina', url: '/' },
        { name: 'Local tiros de esquina', url: '/' },
        { name: 'Visitante tiros de esquina', url: '/' },
      ],
      menuGoles: [
        { name: '1era. Mitad- número exacto de goles', url: '/' },
        { name: '2da. Mitad – número total exacto de goles', url: '/' },
        { name: 'Total goles', url: '/' },
      ],
      menuMitad: [
        { name: '1era. Mitad – resultado', url: '/' },
        { name: '1era. Mitad – corners', url: '/' },
        { name: '1era. Mitad – número exacto de goles', url: '/' },
        { name: '2da. Mitad – número total exacto de goles', url: '/' },
      ],
      menuQAUrl: 'https://n7ielynjxk.execute-api.us-east-2.amazonaws.com/qa/filters',
      menuProdUrl: 'https://0hwm3v926c.execute-api.us-east-2.amazonaws.com/prod/filters',
    };
  },
  methods: {
    closeModalEvent() {
      this.numClicks += 1;
      if (this.numClicks == 2 && this.active != 'none') {
        this.active = 'none';
        this.numClicks = 0;
      }
    },
    selectedOption(opt) {
      if (opt == this.active) {
        this.active = 'none';
        this.numClicks = 0;
      } else {
        if (this.active == 'none') {
          this.active = opt;
        }
      }
      if (opt == 'resultado') {
        this.active = 'none';
        this.numClicks = 0;
        this.$emit('menuChange', {
          name: 'Apuesta derecha',
          component: 'RightBet',
        });
      }
      if (opt == 'parley') {
        this.active = 'none';
        this.numClicks = 0;
        this.$emit('menuChange', {
          name: 'Parlay',
          component: 'Parlay',
        });
      }
      if (opt == 'tips') {
        this.active = 'none';
        this.numClicks = 0;
        this.$emit('menuChange', {
          name: 'Tip Científico',
          component: 'TipGolStats',
        });
      }
    },
    async fillMenus() {
      try {
        const menuOptions = await this.fetchMenuOptions();
        Object.values(this.options).forEach(localOption => {
          const remoteOption = menuOptions.find(option => option.id === localOption.id);
          if (remoteOption && remoteOption.filters) {
            remoteOption.filters.forEach(filter => {
              localOption.options.push({
                name: filter.filter_name,
                id: filter.filter_id,
              });
            });
          }
        });
      } catch (e) {
        throw new Error(e);
      } finally {
        this.$emit('data-fetched');
      }
    },
    async fetchMenuOptions() {
      try {
        const response = await axios.get(this.menuQAUrl);
        if (response && response.data) {
          return response.data;
        }
        return [];
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  created() {
    this.fillMenus();
  },
};
</script>
<style lang="scss" scoped>
.not-cursor {
  cursor: default !important;
}
.bet-opt-disabled {
  opacity: 0.3;
  cursor: default !important;
}
.icon-mitad,
.icon-parlay {
  padding: 28px 120px 32px !important;
  @media screen and (max-width: 1068px) {
    padding: 28px 106px 32px !important;
  }
  @media screen and (max-width: 968px) {
    padding: 28px 91px 32px !important;
  }
  @media screen and (max-width: 868px) {
    padding: 33px 75px 32px !important;
  }
  @media screen and (max-width: 768px) {
    padding: 33px 57px 32px !important;
  }
  @media screen and (max-width: 668px) {
    padding: 30px 43px 32px !important;
  }
  @media screen and (max-width: 568px) {
    padding: 25px 31px 32px !important;
  }
  @media screen and (max-width: 468px) {
    padding: 21px 28px 32px !important;
  }
}
.icon-golstats {
  padding: 40px 104px 32px !important;
  @media screen and (max-width: 1068px) {
    padding: 38px 90px 32px !important;
  }
  @media screen and (max-width: 968px) {
    padding: 38px 76px 32px !important;
  }
  @media screen and (max-width: 868px) {
    padding: 38px 64px 32px !important;
  }
  @media screen and (max-width: 768px) {
    padding: 42px 48px 32px !important;
  }
  @media screen and (max-width: 668px) {
    padding: 38px 35px 32px !important;
  }
  @media screen and (max-width: 568px) {
    padding: 34px 24px 32px !important;
  }
  @media screen and (max-width: 468px) {
    padding: 28px 20px 32px !important;
  }
}
.icon-goles {
  padding: 40px 114px 32px !important;
  @media screen and (max-width: 1068px) {
    padding: 40px 100px 32px !important;
  }
  @media screen and (max-width: 968px) {
    padding: 43px 90px 32px !important;
  }
  @media screen and (max-width: 868px) {
    padding: 43px 72px 32px !important;
  }
  @media screen and (max-width: 768px) {
    padding: 46px 56px 32px !important;
  }
  @media screen and (max-width: 668px) {
    padding: 42px 44px 32px !important;
  }
  @media screen and (max-width: 568px) {
    padding: 38px 33px 32px !important;
  }
  @media screen and (max-width: 468px) {
    padding: 32px 28px 32px !important;
  }
  @media screen and (max-width: 414px) {
    padding: 28px 25px 30px !important;
  }
  @media screen and (max-width: 360px) {
    padding: 30px 27px 32px !important;
  }
}
.bet-tool-menu-items {
  height: 580px;
  width: 1196px;
  margin: 0 auto;
  margin-top: 63px;
  display: grid;
  grid-template-columns: repeat(3, 336px);
  grid-template-rows: repeat(3, 235px);
  grid-gap: 56px 94px;
  @media screen and (max-width: 1196px) {
    width: 1068px;
    grid-gap: 56px 30px;
  }
  @media screen and (max-width: 1068px) {
    width: 968px;
    grid-template-columns: repeat(3, 302px);
    grid-template-rows: repeat(3, 215px);
    height: 530px;
  }
  @media screen and (max-width: 968px) {
    width: 868px;
    grid-template-columns: repeat(3, 269px);
    grid-template-rows: repeat(3, 205px);
  }
  @media screen and (max-width: 868px) {
    width: 768px;
    grid-template-columns: repeat(3, 236px);
    grid-template-rows: repeat(3, 202px);
  }
  @media screen and (max-width: 768px) {
    width: 668px;
    grid-template-columns: repeat(3, 202px);
  }
  @media screen and (max-width: 668px) {
    width: 568px;
    grid-template-columns: repeat(3, 169px);
    grid-template-rows: repeat(3, 182px);
    height: 480px;
  }
  @media screen and (max-width: 568px) {
    width: 468px;
    grid-template-columns: repeat(3, 136px);
    grid-template-rows: repeat(3, 162px);
    height: 440px;
  }
  @media screen and (max-width: 468px) {
    width: 414px;
    grid-template-columns: repeat(3, 118px);
    grid-template-rows: repeat(3, 142px);
    height: 410px;
  }
  @media screen and (max-width: 414px) {
    width: 360px;
    grid-template-columns: repeat(3, 107px);
    grid-gap: 56px 19px;
    grid-template-rows: repeat(3, 132px);
    height: 370px;
  }
  @media screen and (max-width: 360px) {
    width: 280px;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: repeat(3, 165px);
    grid-gap: 16px 19px;
    height: 560px;
  }
  &__item-menu-container {
    position: relative;
  }
  &__item-icon {
    cursor: pointer;
    width: 336px;
    height: 160px;
    margin: 0 0 17px;
    padding: 33px 118px 32px;
    border-radius: 24px;
    box-shadow: 0 2px 7px 0 rgba(26, 29, 31, 0.63);
    background-color: #f9fbfc;
    @media screen and (max-width: 1068px) {
      width: 302px !important;
      padding: 33px 104px 32px;
    }
    @media screen and (max-width: 968px) {
      width: 269px !important;
      padding: 33px 94px 32px;
    }
    @media screen and (max-width: 868px) {
      width: 236px !important;
      padding: 33px 74px 32px;
    }
    @media screen and (max-width: 768px) {
      width: 202px !important;
      padding: 33px 58px 32px;
    }
    @media screen and (max-width: 668px) {
      width: 169px !important;
      padding: 33px 46px 32px;
      height: 150px;
    }
    @media screen and (max-width: 568px) {
      width: 136px !important;
      padding: 33px 34px 32px;
      height: 130px;
    }
    @media screen and (max-width: 468px) {
      width: 118px !important;
      padding: 24px 30px 28px;
      height: 110px;
    }
    @media screen and (max-width: 414px) {
      width: 107px !important;
      padding: 26px 27px 28px;
      height: 100px;
    }
    @media screen and (max-width: 360px) {
      width: 130px !important;
      padding: 33px 34px 32px;
      height: 120px;
    }
  }
  &__item-text {
    width: 100%;
    height: 22px;
    margin-top: 1px;
    font-family: 'Avenir-Pro-Bold';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: -0.5px;
    text-align: center;
    color: #3b3b3b;
    @media screen and (max-width: 1068px) {
      font-size: 21px;
    }
    @media screen and (max-width: 968px) {
      font-size: 19px;
    }
    @media screen and (max-width: 868px) {
      font-size: 17px;
    }
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
    @media screen and (max-width: 668px) {
      font-size: 13px;
      margin-top: -6px;
    }
    @media screen and (max-width: 568px) {
      display: none;
    }
  }
  &__item-text-movil {
    display: none;
    width: 100%;
    height: 22px;
    margin-top: 1px;
    font-family: 'Avenir-Pro-Bold';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: -0.5px;
    text-align: center;
    color: #3b3b3b;

    @media screen and (max-width: 568px) {
      display: block;
      font-size: 14px;
      margin-top: -7px !important;
    }
    @media screen and (max-width: 468px) {
      font-size: 13px;
      margin-top: -7px !important;
    }
    @media screen and (max-width: 414px) {
      font-size: 11px;
      margin-top: -7px !important;
    }
    @media screen and (max-width: 360px) {
      font-size: 13px;
      margin-top: -8px !important;
    }
  }
}
</style>
