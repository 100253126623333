<template>
  <div
    v-click-outside="nameOfCustomEventToCall"
    class="modaltool-menubettool"
    v-if="show"
    :class="{
      'margin-modall-right-1': section == 'goles',
      'margin-modall-right-2': section == 'tiros',
      'margin-modall-right-3': section == 'mitad',
    }"
  >
    <div class="modaltool-menubettool__title">
      <div class="modaltool-menubettool__title__text">
        Haz click para ver los datos históricos más relevantes para cada apuesta:
      </div>
    </div>
    <div class="modaltool-menubettool__opt-container" :class="{ 'grid-2': grid == 2, 'grid-3': grid == 3 }">
      <template v-for="(section, index) in sections">
        <template v-if="(index + 1) % 2 !== 0">
          <div
            class="modaltool-menubettool__opt-container__item float-item-right"
            :key="index"
            @click="goTo(section.id, section)"
          >
            &#8594;&nbsp;&nbsp;{{ section.name }}
          </div>
        </template>
        <template v-else>
          <div
            class="modaltool-menubettool__opt-container__item float-item-left"
            :key="index"
            @click="goTo(section.id, section)"
          >
            &#8594;&nbsp;&nbsp;{{ section.name }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { personalizedBetCorners, personalizedBetGoals, personalizedBetHalf } from '@/utils/analytics';
Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    window.event = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', window.event);
  },
  unbind: function() {
    document.body.removeEventListener('click', window.event);
  },
});
export default {
  name: 'ModalSubMenuBetToolMenu',
  components: {},
  props: {
    grid: {
      type: Number,
      require: true,
    },
    sections: {
      type: Array,
      require: true,
    },
    section: {
      type: String,
      require: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  watch: {},

  methods: {
    nameOfCustomEventToCall() {
      this.$emit('close-modal', false);
    },
    goTo(filterId, filter) {
      if (this.section === 'tiros') {
        personalizedBetCorners({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
          value: filter.name,
        });
      }
      if (this.section === 'goles') {
        personalizedBetGoals({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
          value: filter.name,
        });
      }
      if (this.section === 'mitad') {
        personalizedBetHalf({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
          value: filter.name,
        });
      }

      this.$router.push({
        name: 'match-analysis',
        params: { gameId: '0' },
        query: { filter: filterId.toString(), is_iframe: 'true' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-modall-right-1 {
  margin-left: -428px;
  @media screen and (max-width: 1196px) {
    margin-left: -365px;
  }
  @media screen and (max-width: 1068px) {
    margin-left: -330px;
  }
  @media screen and (max-width: 968px) {
    margin-left: -300px;
  }
  @media screen and (max-width: 868px) {
    margin-left: -266px;
  }
  @media screen and (max-width: 768px) {
    margin-left: -234px;
  }
  @media screen and (max-width: 668px) {
    margin-left: -200px;
  }
  @media screen and (max-width: 568px) {
    margin-left: -330px;
  }
  @media screen and (max-width: 468px) {
    margin-left: -297px;
  }
  @media screen and (max-width: 414px) {
    margin-left: -250px;
  }
  @media screen and (max-width: 360px) {
    margin-left: 0px;
  }
}
.margin-modall-right-2 {
  @media screen and (max-width: 568px) {
    margin-left: -168px;
  }
  @media screen and (max-width: 468px) {
    margin-left: -148px;
  }
  @media screen and (max-width: 414px) {
    margin-left: -127px;
  }
  @media screen and (max-width: 360px) {
    margin-left: -148px;
  }
}
.margin-modall-right-3 {
  @media screen and (max-width: 360px) {
    margin-left: -148px;
  }
}
.grid-2 {
  grid-template-rows: repeat(2, 72px);
}
.grid-3 {
  grid-template-rows: repeat(3, 72px);
}
.float-item-left {
  margin-left: 2px !important;
  @media screen and (max-width: 868px) {
    margin-left: 5px !important;
  }
}
.float-item-right {
  margin-left: 10px !important;
  @media screen and (max-width: 868px) {
    margin-left: 5px !important;
  }
}
.modaltool-menubettool {
  overflow: hidden;
  width: 766px;
  height: auto;
  border-radius: 13px;
  box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.22);
  background-color: #323f50;
  position: absolute;
  z-index: 1;
  margin-top: -25px;
  @media screen and (max-width: 1196px) {
    width: 700px;
  }
  @media screen and (max-width: 1068px) {
    width: 634px;
  }
  @media screen and (max-width: 968px) {
    width: 570px;
  }
  @media screen and (max-width: 868px) {
    width: 505px;
  }
  @media screen and (max-width: 768px) {
    width: 436px;
  }
  @media screen and (max-width: 668px) {
    width: 370px;
  }
  @media screen and (max-width: 568px) {
    width: 470px;
  }
  @media screen and (max-width: 468px) {
    width: 417px;
  }
  @media screen and (max-width: 414px) {
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    width: 280px;
  }
  &__title {
    width: 100%;
    height: 90px;
    &__text {
      width: 70%;
      height: 90px;
      font-family: 'Avenir-Demi';
      font-size: 25px;
      padding-top: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: -0.52px;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      @media screen and (max-width: 1196px) {
        font-size: 24px;
        padding-top: 16px;
      }
      @media screen and (max-width: 1068px) {
        font-size: 23px;
        padding-top: 18px;
      }
      @media screen and (max-width: 968px) {
        font-size: 22px;
        padding-top: 18px;
      }
      @media screen and (max-width: 868px) {
        font-size: 21px;
        padding-top: 18px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
        padding-top: 21px;
        width: 80%;
      }
      @media screen and (max-width: 668px) {
        font-size: 18px;
        padding-top: 23px;
      }
      @media screen and (max-width: 568px) {
        font-size: 22px;
        padding-top: 18px;
      }
      @media screen and (max-width: 468px) {
        font-size: 20px;
        padding-top: 20px;
      }
      @media screen and (max-width: 414px) {
        font-size: 17px;
        padding-top: 23px;
      }
      @media screen and (max-width: 360px) {
        font-size: 15px;
        padding-top: 28px;
        width: 90%;
      }
    }
  }
  &__opt-container {
    width: 766px;
    height: auto;
    background: #36475e;
    display: grid;
    grid-template-columns: repeat(2, 377px);

    grid-gap: 7px 10px;
    padding-bottom: 10px;
    @media screen and (max-width: 1196px) {
      width: 700px;
      grid-template-columns: repeat(2, 344px);
    }
    @media screen and (max-width: 1068px) {
      width: 634px;
      grid-template-columns: repeat(2, 311px);
    }
    @media screen and (max-width: 968px) {
      width: 570px;
      grid-template-columns: repeat(2, 279px);
    }
    @media screen and (max-width: 868px) {
      width: 505px;
      grid-template-columns: repeat(1, 100%);
    }
    @media screen and (max-width: 768px) {
      width: 436px;
    }
    @media screen and (max-width: 668px) {
      width: 370px;
    }
    @media screen and (max-width: 568px) {
      width: 470px;
    }
    @media screen and (max-width: 468px) {
      width: 417px;
    }
    @media screen and (max-width: 414px) {
      width: 360px;
    }
    @media screen and (max-width: 360px) {
      width: 280px;
    }
    &__item {
      background-color: #3a4e6b;
      font-family: 'Avenir-Pro-Medium';
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      line-height: 0.96;
      letter-spacing: -0.48px;
      color: #fff;
      width: 367px;
      height: 64px;
      padding-top: 20px;
      padding-left: 12px;
      margin-top: 7px;
      border-radius: 12px;
      @media screen and (max-width: 1196px) {
        width: 339px;
        font-size: 16.5px;
        padding-top: 24px;
      }
      @media screen and (max-width: 1068px) {
        width: 300px;
        font-size: 15px;
        padding-top: 25px;
      }
      @media screen and (max-width: 968px) {
        width: 271px;
        font-size: 13px;
        padding-top: 27px;
      }
      @media screen and (max-width: 868px) {
        width: 98%;
        font-size: 20px;
        padding-top: 22px;
      }
      @media screen and (max-width: 768px) {
        font-size: 19px;
      }
      @media screen and (max-width: 668px) {
        width: 97.3%;
        font-size: 17px;
        padding-top: 24px;
      }
      @media screen and (max-width: 568px) {
        width: 97.9%;
      }
      @media screen and (max-width: 414px) {
        width: 97.3%;
        font-size: 16px;
        padding-top: 25px;
      }
      @media screen and (max-width: 360px) {
        width: 96.9%;
        font-size: 13px;
      }
    }
    &__item:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
</style>
